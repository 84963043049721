"use client"

import { useEffect } from "react"
import Error from "next/error"
import * as Sentry from "@sentry/nextjs"

export default function GlobalError({ error }: { error: Error }) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html>
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Error statusCode={undefined as any} />
      </body>
    </html>
  )
}
